/*
 * 업무구분: 신탁>메인
 * 화 면 명: MSPTS000M
 * 화면설명: 신탁 메인페이지
 * 작 성 일: 2023.04.06
 * 작 성 자: 송진의
 */
<template>
  <ur-page-container class="fts" :show-title="false" title="신탁" :topButton="false">
    <header class="fts-header">
      <ur-box-container alignV="center" direction="row" class="fts-flex-sb">
        <nav class="btn-content">
          <mo-icon-button class="fts-main-ts" @click="fn_GoJobTypPage" :borderless="true">홈으로</mo-icon-button>
          <mo-icon-button class="fts-gnb" @click="fn_MenuGnb" :borderless="true">메뉴 열기/닫기</mo-icon-button>
        </nav>
        <ul class="fts-main-util row">
          <li class="work-type">            
            <mo-badge :class="lv_visitType === 'vst'? 'visit':'comein'" :text="lv_visitType === 'vst'? '방문':'내방'" />
          </li>
          <li class="logout">
            <a href="javascript:void(0)" @click="fn_ExitApp">로그아웃</a>            
          </li>
        </ul>
      </ur-box-container>
    </header>

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="row">
        <div class="wrap-fts-mainpage">
          <div class="wrap-ts-container">

            <!-- 고객/상품 -->
            <div class="wrap-ctmPrd wrap-ts bg-blue">
              <h1> 고객/상품 </h1>
              <div class="wrap-block">
                <ul class="row">
                  <li class="regist">
                    <mo-button class="fts-main-button" @click="fn_GoPage('custInfoReg')"> <span>고객정보등록</span> </mo-button>
                  </li>
                  <li class="analysis">
                    <mo-button class="fts-main-button" @click="openModal(4)"> <span>고객투자자 성향분석</span> </mo-button>
                  </li>
                  <li class="data">
                    <mo-button class="fts-main-button" @click="fn_GoPage('prdtData')"> <span>상품자료</span> </mo-button>
                  </li>                                    
                </ul>
                <ul class="row">
                  <li class="info">
                    <mo-button class="fts-main-button" @click="openModal(2)"> <span>고객정보조회</span> </mo-button>
                  </li>
                  <li class="represent">
                    <mo-button class="fts-main-button" @click="openModal(3)"> <span>대리인/조력자 등록</span> </mo-button>
                  </li>
                  <li class="wrap-bg">
                  </li>                                    
                </ul>
              </div>
            </div>

            <!-- 영업 -->
            <div class="wrap-sales wrap-ts bg-purple">
              <h1> 영업 </h1>
              <div class="wrap-block">
                <ul class="row">
                  <li class="personal">
                    <mo-button class="fts-main-button" @click="fn_GoPage('newCust')"> <span>개인</span> </mo-button>
                  </li>
                  <li class="wrap-bg-01">
                  </li>
                </ul>
                <ul class="row">
                  <li class="sales-file">
                    <mo-button class="fts-main-button" @click="fn_GoPage('reTrns', '2')"> <span>문서재전송</span> </mo-button>
                  </li>
                  <li class="sales-file">
                    <mo-button class="fts-main-button" @click="fn_GoPage('reTrns')"> <span>파일재전송</span> </mo-button>
                  </li>
                </ul>
              </div>
            </div>

            <!-- 변경/해지/이체 -->
            <div class="wrap-banking wrap-ts bg-skyblue">
              <h1> 변경/해지/이체 </h1>
              <div class="wrap-block">
                <ul class="row">
                  <li class="termination">
                    <mo-button class="fts-main-button" @click="openModal(5)"> <span>변경/해지</span> </mo-button>
                  </li>
                </ul>
                <ul class="row">
                  <li class="banking">
                    <mo-button class="fts-main-button" @click="openModal(6)"> <span>이체</span> </mo-button>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </ur-box-container>

      <!-- Snackbar 스낵바 (테스트 버튼을 클릭하면 스낵바가 나옴. 일단 주석처리함.) -->
      <!-- <mo-button class="test-snackbar" @click="addSnackbar"> 스낵바테스트버튼 </mo-button> -->
      <mo-snackbar ref="stackableSnackbar" stackable :duration="3000"></mo-snackbar>
    </main>
    <!-- //.fts-main -->

    <!-- popup : 고객 > 고객정보등록 -->
    <mo-modal class="fts-modal medium main-popup" ref="custInfoReg" title="고객정보등록" no-foot>
      <template>
        <div class="wrap-modalcontents">
          <ul class="wrap-list">
            <li v-for="(page, index) in lv_pageObj.filter(p=> p.level === 'S' && p.value === 'custInfoReg')" :key="index" >
              <a href="javascript:void(0)" @click="fn_GoSubPage(page)">{{page.text}}</a>
            </li>
          </ul>
        </div>
      </template>
      <template slot="action"></template>
    </mo-modal>

    <!-- popup : 고객 > 고객정보조회 -->
    <mo-modal class="fts-modal medium main-popup li5over" ref="custInfoInqr" title="고객정보조회" no-foot>
      <template>
        <div class="wrap-modalcontents">
          <ul class="wrap-list">
            <li v-for="(page, index) in lv_pageObj.filter(p=> p.level === 'S' && p.value === 'custInfoInqr')" :key="index" >
              <a href="javascript:void(0)" @click="fn_GoSubPage(page)">{{page.text}}</a>
            </li>
          </ul>
        </div>
      </template>
      <template slot="action"></template>
    </mo-modal>

    <!-- popup : 고객 > 대리인/조력자등록 -->
    <mo-modal class="fts-modal medium main-popup" ref="agntAidReg" title="대리인/조력자등록" no-foot>
      <template>
        <div class="wrap-modalcontents">
          <ul class="wrap-list">
            <li v-for="(page, index) in lv_pageObj.filter(p=> p.level === 'S' && p.value === 'agntAidReg')" :key="index" >
              <a href="javascript:void(0)" @click="fn_GoSubPage(page)">{{page.text}}</a>
            </li>
          </ul>
        </div>
      </template>
      <template slot="action"></template>
    </mo-modal>

    <!-- popup : 고객 > 고객투자자성향분석 -->
    <mo-modal class="fts-modal medium main-popup" ref="custIvtrPrpnsAnly" title="고객투자자성향분석" no-foot>
      <template>
        <div class="wrap-modalcontents">
          <ul class="wrap-list">
            <li v-for="(page, index) in lv_pageObj.filter(p=> p.level === 'S' && p.value === 'custIvtrPrpnsAnly')" :key="index" >
              <a href="javascript:void(0)" @click="fn_GoSubPage(page)">{{page.text}}</a>
            </li>
          </ul>
        </div>
      </template>
      <template slot="action"></template>
    </mo-modal>

    <!-- popup : 고객 > 변경/해지 -->
    <mo-modal class="fts-modal medium main-popup li5over" ref="chgRsc" title="변경/해지" no-foot>
      <template>
        <div class="wrap-modalcontents">
          <ul class="wrap-list">
            <li v-for="(page, index) in lv_pageObj.filter(p=> p.level === 'S' && p.value === 'chgRsc')" :key="index" >
              <a href="javascript:void(0)" @click="fn_GoSubPage(page)">{{page.text}}</a>
            </li>
          </ul>
        </div>
      </template>
      <template slot="action"></template>
    </mo-modal>

    <!-- popup : 고객 > 이체 -->
    <mo-modal class="fts-modal medium main-popup li5over" ref="trsf" title="이체" no-foot>
      <template>
        <div class="wrap-modalcontents">
          <ul class="wrap-list">
            <li v-for="(page, index) in lv_pageObj.filter(p=> p.level === 'S' && p.value === 'trsf')" :key="index" >
              <a href="javascript:void(0)" @click="fn_GoSubPage(page)">{{page.text}}</a>
            </li>
          </ul>
        </div>
      </template>
      <template slot="action"></template>
    </mo-modal>

    <!-- 전자서식 전송중 팝업 -->
    <mo-modal class="fts-modal small" ref="progress" no-foot>
      <template>
        <div class="column align-center">
          <div class="div-progress div-progress-circular">
            <mo-determinate-progress shape="circle" :value="valueLoading" size="large" width="20px"></mo-determinate-progress>
            <span class="text-loading">Loading</span>
          </div>
          <!-- <span class="wrap-text blue"> 전자서식 전송중 </span> -->

          <!-- 녹취파일 삭제중일때 -->
          <span class="wrap-text blue"> 녹취파일 전송 및 기기 삭제 중 </span>
        </div>
      </template>
    </mo-modal>

    <!-- popup010 -->
    <msp-ts-010p
      ref="popup010"      
      :popupObj="pPopup010Obj"
    ></msp-ts-010p>

    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPTS010P from '~/src/ui/ts/MSPTS010P'  // 전체메뉴
import TSMenuUtil from '~/src/ui/ts/comm/TSMenuUtil'
import TSAlertPopup from '@/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)  
import TSInfoUtil from '@/ui/ts/comm/TSInfoUtil'
import TSCommUtil from '@/ui/ts/comm/TSCommUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS000M",
  screenId: "MSPTS000M",
  components: {
    'msp-ts-010p': MSPTS010P,
    'ts-alert-popup': TSAlertPopup,
  },  
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ExitApp)
    
    this.fn_InitMenu()
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPTS000M");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      pPopup010Obj: {},
      pAlertPopupObj: {},
      lv_pageObj: [],      
      lv_MsgLogoutConfirm: '로그아웃 하시겠습니까?',
      valueLoading: 0
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal1() {return this.$refs.custInfoReg},    
    modal2() {return this.$refs.custInfoInqr},
    modal3() {return this.$refs.agntAidReg},
    modal4() {return this.$refs.custIvtrPrpnsAnly},    
    modal5() {return this.$refs.chgRsc},
    modal6() {return this.$refs.trsf},
    modal9() {return this.$refs.progress}, // 전자서식 진행바
    lv_visitType() {
      let rtn = this.getStore('tsStore').getters.getBasInfo.data.vstTyp
      return (rtn)? rtn : ''
    },
    lv_basInfo() {
      return this.getStore('tsStore').getters.getBasInfo.data
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
      * Function명 : openModal
      * 설명       : 팝업 열기
      ******************************************************************************/
    openModal(type) {      
      for(let i=1; i<101; i++) {
        this["modal"+type].open(); break;
      }
    },

    /******************************************************************************
      * Function명 : fn_AlertPopup
      * 설명       : alert popup 호출(공통)
      ******************************************************************************/
    fn_AlertPopup (type, pPopupObj) {
      if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
        TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)              
      }
      this.$refs.alertPopup.fn_Open()
    },
    /******************************************************************************
      * Function명 : fn_GoJobTypPage
      * 설명       : 업무구분 페이지 이동
      ******************************************************************************/
    fn_GoJobTypPage(){
      let t_popupObj = {        
        cancel: true,
        confirm: true,
        confirmFunc: async function() {
          this.$bizUtil.tsUtils.saveProcTyp(this) // 초기화
          this.$bizUtil.tsUtils.saveDocData(this) // 고객 서류 초기화
          this.$bizUtil.tsUtils.saveBasInfo(this, { vstTyp: '' }) // 내방/방문 초기화
          this.$bizUtil.fsUtils.saveJobTyp(this) // 업무구분 초기화 (fs / ts)
          await this.$router.push({
            name: 'MSPFS002M'
          })
        },
        content: "수익증권/신탁 선택화면으로 이동하시겠습니까?"
      }
      this.fn_AlertPopup(1, t_popupObj)
    },

    /******************************************************************************
     * Function명 : fn_InitMenu
     * 설명       : 메뉴 초기화
     ******************************************************************************/
    fn_InitMenu() {
      this.lv_pageObj = TSMenuUtil.gfn_InitMenu()
    },

    // 메뉴 열기
    fn_MenuGnb() {
      this.$refs.popup010.fn_Open()
    },
    
    /******************************************************************************
    * Function명 : fn_GoPage
    * 설명       : 페이지 이동
    * 파라미터(type, idx) : 중분류, 인덱스 번호
    ******************************************************************************/    
    fn_GoPage(type, idx = '1') {
      let pageObj = this.lv_pageObj.filter(p=> p.level === 'S' && p.value === type && p.idx === idx)[0]
      console.log('fn_GoPage >', pageObj, type, idx)

      if(!pageObj.params.isRecord) {
        this.getStore('tsStore').dispatch('IS_RECORDING_STOP')
      }

      // 스텝 여부
      if(pageObj.params.isStep) {        
        let t_IsProcess = this.getStore('tsStore').getters.getState.isProcess        
        let procTyp =  this.getStore('tsStore').getters.getBasInfo.data.procTyp
        if ( !t_IsProcess ) {
          this.getStore('tsStore').dispatch('IS_PROCESS_START')
          this.$bizUtil.tsUtils.saveProcTyp(this, pageObj.params.procTyp)
        } else {
          if (pageObj.params.procTyp !== procTyp) {
            this.$bizUtil.tsUtils.saveProcTyp(this, pageObj.params.procTyp)
          }
        } 
      } else {        
        this.$bizUtil.tsUtils.saveProcTyp(this)
        this.getStore('tsStore').dispatch('IS_PROCESS_STOP')
      }

      // 개인고객등록, 개인고객조회 전환 시
      if(pageObj.page === 'MSPTS100M') {
        if(pageObj.params.isSearchScrn) {
          let t_isSearchScrn = this.getStore('tsStore').getters.getState.isSearchScrn                
          if ( !t_isSearchScrn ) {
            this.getStore('tsStore').dispatch('IS_SEARCHSCRN_START')
          }
        } else {
          this.getStore('tsStore').dispatch('IS_SEARCHSCRN_STOP')
        }
      }
            
      this.$router.push({
        name: pageObj.page,
        params: pageObj.params
      })
    },
    /******************************************************************************
    * Function명 : fn_GoSubPage
    * 설명       : 페이지 이동
    * 파라미터(obj) : 페이지 객체
    ******************************************************************************/    
    fn_GoSubPage(obj) {
      let pageObj = obj      

      if(!pageObj.params.isRecord) {
        this.getStore('tsStore').dispatch('IS_RECORDING_STOP')
      }

      // 스텝 여부
      if(pageObj.params.isStep) {        
        let t_IsProcess = this.getStore('tsStore').getters.getState.isProcess        
        let procTyp =  this.getStore('tsStore').getters.getBasInfo.data.procTyp
        if ( !t_IsProcess ) {
          this.getStore('tsStore').dispatch('IS_PROCESS_START')
          this.$bizUtil.tsUtils.saveProcTyp(this, pageObj.params.procTyp)
        } else {
          if (pageObj.params.procTyp !== procTyp) {
            this.$bizUtil.tsUtils.saveProcTyp(this, pageObj.params.procTyp)
          }
        } 
      } else {        
        this.$bizUtil.tsUtils.saveProcTyp(this)
        this.getStore('tsStore').dispatch('IS_PROCESS_STOP')
      }

      // 개인고객등록, 개인고객조회 전환 시
      if(pageObj.page === 'MSPTS100M') {
        if(pageObj.params.isSearchScrn) {
          let t_isSearchScrn = this.getStore('tsStore').getters.getState.isSearchScrn                
          if ( !t_isSearchScrn ) {
            this.getStore('tsStore').dispatch('IS_SEARCHSCRN_START')
          }
        } else {
          this.getStore('tsStore').dispatch('IS_SEARCHSCRN_STOP')
        }
      }
            
      this.$router.push({
        name: pageObj.page,
        params: pageObj.params
      })
    },

    /******************************************************************************
    * Function명 : fn_ExitApp
    * 설명       : App 종료 액션
    ******************************************************************************/    
    fn_ExitApp () {
    // console.log('##### fn_ExitApp #####')
    let lv_Vm = this
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: "알림",
          content: this.lv_MsgLogoutConfirm,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
            window.fdpbridge.exec('logoutPlugin', {}, () => {}, () => {})
            console.log('fn_ExitApp - logout !')
            lv_Vm.$router.push({
              name: 'MSPBC600M'
            })
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },
  }
};
</script>
<style scoped>
</style>